import { render, staticRenderFns } from "./ProductInStockLocation.vue?vue&type=template&id=9dd0f266&scoped=true"
import script from "./ProductInStockLocation.vue?vue&type=script&lang=js"
export * from "./ProductInStockLocation.vue?vue&type=script&lang=js"
import style0 from "./ProductInStockLocation.vue?vue&type=style&index=0&id=9dd0f266&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dd0f266",
  null
  
)

export default component.exports