<template>
  <div>
    <b-sidebar
      id="edit-product-stock-location"
      ref="modal"
      header-class="headerModalToggle"
      centered
      right
      width="700px"
      :title="$t('EditProduct')"
      hide-footer
      no-close-on-esc
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('EditProduct')} ` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-form style="padding: 10px">
        <b-form-group
          id="input-group-1"
          :label="`${$t('SelectLocationName')}:`"
          label-for="input-1"
        >
          <vue-select
            v-model="edit.locationName"
            style="min-width: 270px;"
            :options="getLocationsByType"
            :placeholder="$t('SelectLocation')"
            label="locationName"
          />
          <!-- :reduce="(e) => e.locationName" -->
        </b-form-group>



      </b-form>

      <div class="buttonsEverywhereToggle">
        <button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitLocation"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
// import {
//   required, minLength,
// } from 'vuelidate/lib/validators';
// import maxLength from 'vuelidate/lib/validators/maxLength';

export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['edit'],
  data() {
    return {
      form: {
        productInStockId: null,
        locationId: null,
      },
      locationName: null,
    }
  },
  validations: {
    form: {
      // locationName: {
      //   required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // locationType: {
      //   // required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // notes: {
      //   required,
      //   minLength: minLength(1),
      // },
      // doorNumber: {
      //   // required,
      //   minLength: minValue(1),
      //   numeric,
      // },
      // contactTitle: {
      //   // required,
      //   minLength: minLength(2),
      //   // alpha,
      // },
      // city: {
      //   required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // postalCode: {
      //   required,
      //   minLength: minLength(3),
      //   alphaNum,
      // },
      // country: {
      //   required,
      //   minLength: minLength(3),
      //   // alpha,
      // },
      // // phone: {
      // //   required,
      // //   minLength: minLength(9),
      // //   numeric,
      // // },
      // email: {
      //   // required,
      //   email,
      // },
      // webSite: {
      //   // required,
      //   minLength: minLength(3),
      //   // alphaNum,
      // },
    },
  },
  computed: {
    ...mapGetters([
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getLocation',
      'getLocationTypes',
      'getLocationsByType',
    ]),
    // formattedPhoneNumber() {
    //   const countryCode = this.user.countryCode;
    //   const callingCode = this.countryCallingCode[countryCode];
    //   const phoneNumber = this.user.phoneNumber;
    //   return `+${callingCode} ${phoneNumber}`;
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    'edit.productInStockId': function (value) {
      this.form.productInStockId = value
    },
    // eslint-disable-next-line func-names
    'edit.locationName.locationId': function (value) {
      this.form.locationId = value
    },
    // eslint-disable-next-line func-names
    'edit.locationId': function (value) {
      this.form.locationId = value
    },
    // locationName(value) {
    //   this.form.locationId = value
    // },
  },
  mounted() {
    // this.editOrderLocation = this.form
  },
  methods: {
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true;
        this.form.phone = n.nationalNumber;
        this.form.countryCode = n.countryCode;
      } else {
        this.form.countryCode = '';
        this.isPhoneNumberValid = false;
        return;
      }
      console.log();
    },
    onSubmitLocation() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      // console.log(this.form)
      this.$emit('onCancelToggle');
      this.$emit('editPassive', this.form)
      this.$refs.modal.hide()
      this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onReset()
    },
    onReset() {
      // Reset our form values
      // this.form.locationId = ''
      // this.edit.productInStockId = ''
      // this.edit.locationType = ''
      // this.form.notes = ''
      // this.form.contactTitle = ''
      // this.form.city = ''
      // this.form.doorNumber = ''
      // this.form.postalCode = ''
      // this.form.country = ''
      // this.form.phone = ''
      // this.form.email = ''
      // this.form.webSite = ''
      // this.form.countryCode = '';
      // Trick to reset/clear native browser form validation state
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-select {
  font-size: 13px; /* Adjust the font size to your preference */
  height: 36px; /* Adjust the height to your preference */
  padding: 6px 12px; /* Adjust the padding to your preference */
}
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>

<style>
.blinori {
  background-color: #242F6E;
  color: white;
}

.blinori button {
  background-color: white;
  border-radius: 10px;
}

.leo {
  background-color: white;
  border-radius: 10px;
}

.form.control {
  width: 100%;
}
</style>
