<template>
  <div >
    <section class="main no-flex">
      <ProductInStockLocationListing />
    </section>
  </div>
</template>

<script>
import ProductInStockLocationListing from '../components/productsInStock/ProductInStockLocationListing.vue';

export default {
  name: 'Home',
  components: {
    ProductInStockLocationListing,
  },
}
</script>
<style scoped lang="scss">
  .main{
    padding: 75px 15px 15px 120px;
  }

@media screen and (max-width: 1700px){
  .main{
    padding: 75px 15px 15px 100px;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 10px 15px 90px !important;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 10px 15px 10px !important;
  }
}
</style>
